export const projStatus = [
"New Lead",
"Evaluation",
"Layout",
"Quoted",
"Approved",
"Approved-Contract Signed",
"Approved-Material Ordered",
"Install in Progress",
"Install Completed",
"Install Invoiced",
"Project Cancelled",
"Project Delayed",
"Project Closed",
"Project Refusal",
"Recovery",
"Service",
"Rental",
]