import React from "react";

import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

import { useQuery } from "@tanstack/react-query";

import { fetchActiveProjects, queryClient } from "../tools/http";
import LongProjectTable from "../components/Project/LongProjectTable";
import { Typography } from "@mui/material";

function Operations() {
  const { data } = useQuery({
    queryKey: ["projectlist"],
    queryFn: ({ signal }) => fetchActiveProjects({ signal }),
    staleTime: 10000,
  });
  const newProjects = data.filter((project) => project.statusFlags.isAccepted === false);
  const activeProjects = data.filter((project) => project.statusFlags.isAccepted === true);

  return (
    <Grid2 spacing={2} p={2} container>
      <Grid2 xs={12}>
              <Typography variant="h4" color="primary">Operations</Typography>
      </Grid2>

      <LongProjectTable projects={newProjects} header="New Projects" />
      <LongProjectTable projects={activeProjects} header="Active Projects" />

    </Grid2>
  );
}

export default Operations;

export async function loader({ params }) {
  return queryClient.fetchQuery({
    queryKey: ["projectlist"],
    queryFn: ({ signal }) => fetchActiveProjects({ signal }),
  });
}
