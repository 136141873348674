import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient();

export async function fetchCustomer({ id, signal }) {
  // const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/customer/64ee073025b21993038fc073`);
  // console.log(id)
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/customer/${id}`, {signal});
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function fetchContacts() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/contact`);
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function newProject(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/project`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function updateCustomer(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/customer`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function newNote({data, id}) {
  // console.log(id);
  // console.log(JSON.stringify(data));
  const URL = `${process.env.REACT_APP_BACKEND_API}/customer/note?id=${id}`

  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
};

export async function sendToQb(data) {
  // console.log(JSON.stringify(data));
  console.log("yup")
  const URL = `${process.env.REACT_APP_BACKEND_API}/customer/quickbooks`

  const response = await fetch(URL, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function fetchActiveProjects() {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/project`);
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
 }

export async function updateProject(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/project/${data._id}`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    console.log(resData);
    return resData;
  }
}

export async function fetchProject({ id, signal }) {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/project/${id}`, {signal});
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function newLayoutJob(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/layout`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function newQuote(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/finance`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function updateFinance(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/finance/${data._id}`, {
    method: "PATCH",
    body: JSON.stringify(data.values),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}

export async function login(data) {
  // console.log(JSON.stringify(data));
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/login`, {
    method: "POST",
    body: JSON.stringify(data),
    // mode: 'no-cors',
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    // console.log(response)
  } else {
    const resData = await response.json();
    // console.log(resData);
    return resData;
  }
}