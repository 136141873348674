import React, { lazy, Suspense, useState, useCallback } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./tools/http";

// Importing loaders
import { loader as projectLoader } from "./pages/Project";
import { loader as activeProjectsLoader } from "./pages/Operations";
import { loader as customerLoader } from "./pages/Customer";
import { loader as contactsLoader } from "./components/Project/NewProjectForm";

import { AuthContext } from "./store/auth-context";
import Auth from "./pages/Auth";

const Home = lazy(() => import("./pages/Home"));
const RootLayout = lazy(() => import("./pages/Root"));
const Intake = lazy(() => import("./pages/Intake"));
const Lead = lazy(() => import("./pages/Lead"));
const Testing = lazy(() => import("./pages/Testing"));
const Contacts = lazy(() => import("./pages/Contacts"));
const Customer = lazy(() => import("./pages/Customer"));
const NewProjectForm = lazy(() =>
  import("./components/Project/NewProjectForm")
);
const CustomerToQb = lazy(() => import("./components/Customer/CustomerToQb"));
const NewNoteForm = lazy(() => import("./components/Notes/NewNoteForm"));
const Project = lazy(() => import("./pages/Project"));
const ExpenseTable = lazy(() =>
  import("./components/Finance/pages/ExpenseTable")
);
const Operations = lazy(() => import("./pages/Operations"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "auth", element: <Auth /> },
      { path: "intake", element: <Intake /> },
      { path: "lead/:id", element: <Lead /> },
      { path: "contacts", element: <Contacts /> },
      { path: "test", element: <Testing /> },
      {
        path: "project/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Project />
          </Suspense>
        ),
        loader: projectLoader,
        children: [
          {
            path: "finances",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <ExpenseTable />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "Operations",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Operations />
          </Suspense>
        ),
        loader: activeProjectsLoader,
      },
      {
        path: "customer/:id",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Customer />
          </Suspense>
        ),
        loader: customerLoader,
        children: [
          {
            path: "newproject",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <NewProjectForm />
              </Suspense>
            ),
            loader: contactsLoader,
          },
          {
            path: "newnote",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <NewNoteForm />
              </Suspense>
            ),
          },
          {
            path: "quickbooks",
            element: (
              <Suspense fallback={<div>Loading...</div>}>
                <CustomerToQb />
              </Suspense>
            ),
            loader: contactsLoader,
          },
        ],
      },
    ],
  },
]);

function App() {
  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(false);
  const [email, setEmail] = useState(null);

  const login = useCallback((uid, token, email) => {
    setToken(token);
    setUserId(uid);
    setEmail(email);

  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setEmail(null);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        login: login,
        logout: logout,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<div>Loading...</div>}>
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
}

export default App;
